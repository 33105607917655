<template>
    <div class="monitoring-table-wrapper">
        <div class="monitoring-table">
            <div class="monitoring-table-header-wrapper">
                <div class="monitoring-table-title">
                    <p class="table-name"><b>Router / Stränge</b><span class="subtitle"> - Zugeordnete Gruppen</span></p>
                </div>
            </div>
            <div class="monitoring-table-body-wrapper">
                <div class="table-wrapper">
                    <div v-for="device in getLinkedDevices" :key="device._id" class="monitoring-table-form-wrapper">
                        <div class="monitoring-table-form" @click="getOnlyTestList(device)" :class="{'selected-block': device.showDetails, 'hight-temperature': device.log.length !== 0, 'selected-device': Object.keys(getCurrentTestingDevice).length !== 0 && device.flat._id === getCurrentTestingDevice.flat._id, 'deactivated-flat': device.is_active === false}">
                            <div class="form-title">
                                <img v-if="device.status === 1" src="/images/TRB-active-status.png" alt="work status" class="form-status">
                                <img v-else src="/images/TRB-unactive-status.png" alt="dont work status" class="form-status">
                                <span class="strang-name">Wohnung {{ device.flat.flat_number}} - {{ device.flat.location }}</span>
                            </div>
                            <span class="trb-name" v-if="!device.showDetails">TRB{{ device.uid }}</span>
                            <span class="form-date" v-if="!device.showDetails">{{ formatDate(device.started_at) }}</span>
                            <div class="form-control">
                                <div class="form-indicators">
                                    <img v-if="device.self_diagnostic_status === 1" src="/images/done.png" alt="work status" class="icon-wrapper">
                                    <img v-else-if="device.self_diagnostic_status === 2" src="/images/error.png" alt="error status" class="icon-wrapper">
                                    <img v-else src="/images/checkmark.png" alt="no status" class="icon-wrapper">
                                    <img v-if="device.functional_test_status === 1" src="/images/done.png" alt="work status" class="icon-wrapper">
                                    <img v-else-if="device.functional_test_status === 2" src="/images/error.png" alt="error status" class="icon-wrapper">
                                    <img v-else src="/images/checkmark.png" alt="no status" class="icon-wrapper">
                                </div>
                                <div class="form-button">
                                    <button @click="getLastFlushProcess(device)" :class="{'rotate-button': device.showDetails, 'rotate-right': !device.showDetails}"></button>
                                </div>
                            </div>
                        </div>
                        <div v-if="device.showDetails" class="hidden-block" :class="{'deactivated-flat': device.is_active === false, 'hight-temperature': device.log.length !== 0, 'selected-device': Object.keys(getCurrentTestingDevice).length !== 0 && device.flat._id === getCurrentTestingDevice.flat._id}">
                            <div class="hidden-block-container">
                                <div class="content-wrapper">
                                    <div v-if="device.log.length !== 0" class="warning-block-wrapper">
                                        <div class="warning-block">
                                            <div class="warning-block-title" v-for="log in device.log" :key="log">
                                                <img src="/images/error.png" alt="error icon">
                                                <span v-html="log"></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="router-table-head">
                                        <div class="router-table-row">
                                            <div class="router-table-column" v-for="field in firstColumns" :key="field">
                                                <div class="router-column">
                                                    <div class="router-column-title" :class="{'router-column-device-title': field.text === 'Datum & Uhrzeit'}">{{ field.text }}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="router-table-body">
                                        <div class="router-table-row">
                                            <div class="router-table-column">
                                                <span class="left-title">TRB{{ device.uid }}</span>
                                                <span class="right-title">{{ formatDate(device.started_at) }}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="second-row-body">
                                        <div class="router-table-row">
                                            <span class="router-table-title">Strangname</span>
                                            <div class="router-table-block content">
                                                <span>{{ device.flat.paul_syntax }}</span>
                                            </div>
                                        </div>
                                        <div class="router-table-row details-row">
                                            <span class="router-table-title">Temperatur</span>
                                            <div class="router-table-block">
                                                <span v-if="!device.temperature" >-</span>
                                                <span v-else class="result-table-data" :class="{'result-table-error-data': device.temperature >= 25}">{{ validationParametrs(device.temperature) }} &deg;C</span>
                                            </div>
                                        </div>
                                        <div class="router-table-row details-row">
                                            <span class="router-table-title">SOLL-Wert</span>
                                            <div class="router-table-block">
                                                <span v-if="device.set_point || device.set_point === 0">{{ validationParametrs(device.set_point / 100) }} %</span>
                                                <span v-else>-</span>
                                            </div>
                                        </div>
                                        <div class="router-table-row details-row">
                                            <span class="router-table-title">IST-Wert</span>
                                            <div class="router-table-block">
                                                <span v-if="device.relative_point || device.relative_point === 0">{{ validationParametrs(device.relative_point / 100) }} %</span>
                                                <span v-else>-</span>
                                            </div>
                                        </div>
                                        <div class="router-table-row details-row">
                                            <span class="router-table-title">Status</span>
                                            <div class="router-table-block">
                                                <span v-if="device.device_values_status === 1" class="result-table-data">Ok</span>
                                                <span v-else-if="device.device_values_status === 2" class="result-table-error-data">Fehler</span>
                                                <span v-else>-</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="!device.is_processing" class="buttons-wrapper">
                                        <button v-if="device.is_active === true" class="router-button unlink" @click="activateDevice(device, false)">Deaktivieren</button>
                                        <button v-else-if="device.is_active === false" class="router-button unlink" @click="activateDevice(device, true)">Aktivieren</button>
                                        <button class="router-button" :disabled="device.functional_test_status === 3 || device.self_diagnostic_status === 2 || !device.self_diagnostic_status" @click="startFlushingProcess(device)" :class="{'disabled-button': device.functional_test_status === 3 || device.self_diagnostic_status === 2 || !device.self_diagnostic_status, 'testing': device.self_diagnostic_status === 1 && device.functional_test_status !== 3}">Strangspülung aktivieren</button>
                                        <button class="router-button status-button" @click="getDeviceStatus(device)">Status</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="device.flushingProcess" class="flushing-block">
                            <div class="flushing-content-wrapper">
                                <div class="form-title">
                                    <div class="form-title-wrapper">
                                        <img v-if="getCurrentLastFlushProcess && getCurrentLastFlushProcess.status === 1" src="/images/done.png" alt="work status" class="form-status">
                                        <img v-else-if="getCurrentLastFlushProcess.status === 2" src="/images/error.png" alt="error status" class="form-status">
                                        <img v-else src="/images/process_icon.png" alt="process status" class="form-status process">
                                        <p class="block-name"><b>Spülvorgang</b></p>
                                    </div>
                                </div>
                                <div v-if="getCurrentLastFlushProcess.status === 1" class="flushing-text">
                                    Die Kaltwasserspülung war erfolgreich!
                                </div>
                                <div v-else class="flushing-text">
                                    Die Kaltwasserspülung wurde soeben gestartet. Bitte haben Sie etwas Geduld bis sie abgeschlossen wurde.
                                </div>
                                <div class="test-table-form-content">
                                    <div v-for="(log, index) in getCurrentLastFlushProcess.log" :key="index" class="content-item">
                                        <img v-if="checkLastElem(log)" src="/images/error.png" alt="error status" class="form-status">
                                        <img v-else-if="checkLastDownloadElem(log)" src="/images/process_icon.png" alt="process status" class="form-status process">
                                        <img v-else src="/images/done.png" alt="work status" class="form-status">
                                        <p class="content-text">{{ log }}</p>
                                    </div>
                                </div>
                                <div class="timer-wrapper">
                                    <img v-if="getCurrentLastFlushProcess.status === 1" src="/images/rinsing_icon_ok.png" alt="Timer" class="rinsing-image">
                                    <img v-else src="/images/rinsing_icon.png" alt="Timer" class="rinsing-image">
                                    <p v-if="getCurrentLastFlushProcess.status != 3" class="time"><span :class="{'time-error': getCurrentLastFlushProcess.status === 2}">{{ validationTimeTest(getCurrentLastFlushProcess.created_at, getCurrentLastFlushProcess.updated_at) }}</span> min</p>
                                    <p v-else class="time time-current-test"><span><span v-if="minutes < 10">0</span>{{ minutes }}:<span v-if="seconds < 10">0</span>{{ seconds }}</span> min</p>
                                </div>
                                <button v-if='getCurrentLastFlushProcess.status === 3' class="flushing-block-button" @click="stopFlushingProcess(device)">Abbrechen</button>
                                <button v-else class="flushing-block-button" @click="device.flushingProcess = false">Ok</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import validationTimeZone from "../mixins/validationTimeZone.mixin";
export default {
  name: 'Monitoring Linked Flats',
  mixins: [validationTimeZone],
  data() {
    return {
        firstColumns: [
            { text: 'Seriennummer von Router'},
            { text: 'Datum & Uhrzeit'}
        ],
        secondColumns: [
            { text: ''},
            { text: 'Strangname'}
        ],
        minutes: 0,
        seconds: 0,
        interval: null,
    }
  },
  sockets: {
    update: function(process) {
        if(process.type === 'flush') {
            if(process.data !== null) {
                if(process.data.status === 2 || process.data.status === 1) {
                    this.minutes = 0;
                    this.seconds = 0;
                    this.toggleTimer(process.data.status);
                } else if(process.data.status === 3 && this.minutes === 0 && this.seconds === 0) {
                    this.timer(process.data.created_at)
                    this.toggleTimer(process.data.status);
                }
                this.updateCurrentFlush(process.data);
            }
        }
    }
  },
  computed: {
    ...mapGetters(['getLinkedDevices', 'getCurrentLastFlushProcess', 'getCurrentTestingDevice'])
  },
  methods: {
    ...mapMutations(['setCurrentFlushingProcessConnection', 'updateCurrentFlush', 'cleanCurrentFlushProcess', 'cleanLogs', 'setCurrentTestingDevice', 'setCurrentTestingDevice', 'cleanFunctionalTestsList']),
    formatDate(date) {
        const timeZone = this.validationTimeZone(date);
        return moment(timeZone).format('DD.MM.YYYY HH:mm');
    },
    validationParametrs(data) {
        if(Number.isInteger(data)) {
            return data.toFixed(1).replace(/\./g, ',');
        } else {
            const num = +(Math.round(data + "e+1") + "e-1");
            return Number.isInteger(num) ? String(num.toFixed(1)).replace(/\./g, ',') : String(num).replace(/\./g, ',');
        }
    },
    validationTimeTest(start, end){
        const startTest = moment(start).format('HH:mm:ss');
        const endTest = moment(end).format('HH:mm:ss');

        return moment.utc(moment.duration(endTest) - moment.duration(startTest)).format('mm:ss');
    },
    startFlushingProcess(device) {
        this.$socket.emit('start_flushing_process', {
            device: device._id,
            flat: device.flat._id,
            token: localStorage.getItem('token')
        });
        device.flushingProcess = true;
        device.is_processing = true;
    },
    stopFlushingProcess(device) {
        this.$socket.emit('stop_flushing_process', {
            flush: this.getCurrentLastFlushProcess._id,
            token: localStorage.getItem('token')
        });
        this.toggleTimer(2);
    },
    incrementTime() {
      this.seconds = parseInt(this.seconds) + 1;
      if(this.seconds == 60) {
        this.minutes = parseInt(this.minutes) + 1;
        this.seconds = 0;
      }
    },
    toggleTimer(status) {
      if (status === 2 || status === 1) {
        clearInterval(this.interval);
      } else {
        this.interval = setInterval(this.incrementTime, 1000);
      }
    },
    activateDevice(device, activateData) {
        this.$socket.emit('devices/change_activity', {
            token: localStorage.getItem('token'),
            _id: device._id,
            is_active: activateData
        })
    },
    checkLastElem(log) {
        const lastElem = this.getCurrentLastFlushProcess.log[this.getCurrentLastFlushProcess.log.length - 1];
        if(lastElem === log && this.getCurrentLastFlushProcess.status === 2) {
            return true;
        } else {
            return false;
        }
    },
    checkLastDownloadElem(log) {
        const lastElem = this.getCurrentLastFlushProcess.log[this.getCurrentLastFlushProcess.log.length - 1];
        if(lastElem === log && this.getCurrentLastFlushProcess.status === 3) {
            return true;
        } else {
            return false;
        }
    },
    getLastFlushProcess(device) {
        if(device.showDetails === false) {
            this.setCurrentTestingDevice(device);
            this.$socket.emit('get_last_flush', {
                token: localStorage.getItem('token'),
                device: device._id,
                flat: device.flat._id
            });
            this.$socket.emit('logs', {
                token: localStorage.getItem('token'),
                device: device._id,
                flat: device.flat._id
            });
            this.$socket.emit('functional_tests', {
                device: device._id,
                flat: device.flat._id,
                token: localStorage.getItem('token')
            });
        } else {
            if(device.flushingProcess === false) {
                this.setCurrentTestingDevice({});
                this.cleanFunctionalTestsList([]);
                this.cleanLogs([]);
            } else {
                device.flushingProcess = false;
                this.cleanCurrentFlushProcess(null);
                this.setCurrentTestingDevice({});
                this.cleanFunctionalTestsList([]);
                this.cleanLogs([]);
            }
        }
        device.showDetails = !device.showDetails;
    },
    getOnlyTestList(device) {
        this.setCurrentTestingDevice(device);
        this.$socket.emit('functional_tests', {
            device: device._id,
            flat: device.flat._id,
            token: localStorage.getItem('token')
        });
        this.$socket.emit('logs', {
            token: localStorage.getItem('token'),
            device: device._id,
            flat: device.flat._id
        });
    },
    timer(date) {
        const timeDifference = Math.abs(new Date() - new Date(date)) / 1000 ;
        this.minutes = Math.trunc(timeDifference / 60);
        this.seconds = Math.trunc(timeDifference % 60);
    },
    getDeviceStatus(device) {
        this.$socket.emit('devices/status', {
            device: device._id,
            flat: device.flat._id,
            token: localStorage.getItem('token')
        });
    }
  }
}
</script>
<style lang="scss" scoped>
.monitoring-table-wrapper {
    width: 100%;
    height: 100%;
    background: #EFF9FF;
    border-radius: 10px;

    .monitoring-table {
        height: calc(100% - 40px);
        padding: 20px;
    }

    .monitoring-table-header-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .monitoring-table-title {
            padding-bottom: 5px;
            border-bottom: 1px solid rgba(2, 34, 89, 0.3);

            .table-name {
                font-size: 18px;
                color: #022259;

                .subtitle {
                    font-weight: 500;
                    font-size: 14px;
                }
            }
        }
    }

    .monitoring-table-body-wrapper {
        margin-top: 21px;
        width: 100%;
        height: calc(100% - 40px);

        .table-wrapper {
            overflow-x: auto;
            height: calc(100% - 5px);

            .selected-block {
                border-bottom-right-radius: 0 !important;
                border-bottom-left-radius: 0 !important;
                border-bottom: 1px solid #90A3BD !important;
                grid-template-columns: none !important;
                display: flex !important;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
            }

            .hight-temperature {
                border: 1px solid red;
            }

            .selected-device {
                border: 1px solid #022259 !important;
            }

            .monitoring-table-form-wrapper {
                margin-top: 10px;
            }

            .monitoring-table-form-wrapper:nth-child(1) {
                margin-top: 0;
            }

            .monitoring-table-form {
                display: grid;
                grid-template-columns: 2.3fr 4fr 1fr 1fr;
                grid-column-gap: 5px;
                align-items: center;
                border-radius: 10px;
                background: #FFFFFF;
                height: 30px;
                padding: 5px 10px 5px 10px;

                .form-title {
                    width: auto;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    max-width: 130px;
                    min-width: 125px;

                    .form-status {
                        margin-right: 5px;
                        width: 15px;
                        height: 15px;
                    }

                    .strang-name {
                        font-weight: 500;
                        font-size: 14px;
                        color: #022259;
                    }
                }

                span {
                    font-size: 14px;
                    color: #022259;
                }

                .trb-name {
                    text-align: center;
                }

                .form-date {
                    min-width: 110px;
                    text-align: center;
                }

                .form-control {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    min-width: 80px;
                    align-items: center;

                    .form-indicators {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        width: 45px;
                        align-items: center;

                        .icon-wrapper {
                            width: 20px;
                            height: 20px;
                            cursor: pointer;
                        }
                    }

                    button {
                        margin-top: 3px;
                        width: 20px;
                        height: 20px;
                        background: url('/images/show.png') no-repeat 50% 50%;
                        background-size: contain;
                        border: none;
                    }

                    .rotate-button {
                        transform: rotate(180deg);
                        transition: transform 1s ease;
                    }

                    .rotate-right {
                        transform: rotate(360deg);
                        transition: transform 1s ease;
                    }
                }
            }

            .deactivated-flat {
                background: rgb(230, 230, 230) !important;
            }

            .hight-temperature {
                border: 1px solid red;
            }

            .hidden-block {
                border-bottom-right-radius: 10px;
                border-bottom-left-radius: 10px;
                background: #FFFFFF;
                border-top: none !important;
                position: relative;
                height: auto;
                margin-bottom: 10px;

                .hidden-block-container {

                    .warning-block-wrapper {
                        padding: 10px 10px 0 10px;
                    }

                    .warning-block {
                      padding: 10px;
                      background: rgb(255, 202, 202);
                      border-radius: 10px;
                      line-height: 1.2;
                      font-size: 14px;

                      .warning-block-title:first-child {
                        margin-top: 0 !important;
                      }
                      
                      .warning-block-title {
                        margin-top: 10px;
                        display: flex;
                        flex-direction: row;
                        align-items: center;

                        img {
                          width: 20px;
                          height: 20px;
                          margin-right: 5px;
                        }

                        span {
                            font-size: 14px;
                        }
                      }
                    }

                    .router-table-head {
                        padding: 10px 10px 9px 10px;

                        .router-table-row {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;

                            .router-table-column {
                                .router-column {
                                    .router-column-title {
                                        font-size: 14px;
                                        font-weight: 500;
                                        color: #022259;
                                    }

                                    .router-column-device-title {
                                        margin-right: 20px;
                                    }
                                }
                            }
                        }
                    }

                    .router-table-body {
                        padding: 0 10px 0 10px;

                        .router-table-row {
                            display: grid;
                            grid-template-columns: 100%;
                            align-items: center;

                            .router-table-column {
                                display: flex;
                                justify-content: space-between;
                                border: 2px solid #022259;
                                border-radius: 7px;
                                background: #FFFFFF;
                                height: 25px;
                                padding: 3px 10px 3px 10px;
                                align-items: center;

                                span {
                                    color: #022259;
                                    font-size: 14px;
                                }
                            }
                        }
                    }

                    .second-row-body {
                        padding: 10px 10px 10px 10px;
                        height: 58px;
                        display: grid;
                        grid-template-columns: 2fr 1fr 1fr 1fr 1fr;
                        grid-column-gap: 10px;

                        .details-row {
                            .router-table-title, span {
                                margin: 0 auto;
                            }
                        }

                        .router-table-row {
                            display: flex;
                            flex-direction: column;
                            justify-content: space-between;

                            .router-table-title {
                                font-size: 14px;
                                font-weight: 500;
                                color: #022259;
                            }

                            .content {
                                padding: 3px 10px 3px 10px !important;
                            }

                            .router-table-block {
                                display: flex;
                                border: 2px solid #022259;
                                border-radius: 7px;
                                background: #FFFFFF;
                                height: 25px;
                                padding: 3px 3px 3px 3px;
                                align-items: center;

                                span {
                                    font-size: 14px;
                                    color: #022259;
                                }

                                .result-table-data {
                                    color: green;
                                }

                                .result-table-error-data{
                                    color: red;
                                }
                            }
                        }
                    }

                    .buttons-wrapper {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        padding: 0 10px 10px 10px;

                        button {
                            width: 250px;
                            height: 35px;
                            background: #022259;
                            border: 2px solid #022259;
                            border-radius: 18px;
                            color: #FFFFFF;
                            font-weight: 300;
                        }

                        .unlink {
                            margin-right: 10px;
                            width: 34%;
                            background: #022259;
                            color: #FFFFFF;
                        }

                        .unlink:hover {
                            background: #FFFFFF;
                            color: #022259;
                        }

                        .testing {
                            width: 40%;
                            background: #022259;
                            color: #FFFFFF;
                            margin-right: 10px;
                        }

                        .testing:hover {
                            background: #FFFFFF;
                            color: #022259;
                        }

                        .router-button {
                            border: 2px solid #022259;
                            border-radius: 18px;
                            font-size: 14px;
                        }

                        .status-button {
                            width: 20%;
                        }

                        .status-button:hover {
                            background: #FFFFFF;
                            color: #022259;
                        }

                        .disabled-button {
                            width: 40%;
                            background: #acacac;
                            border-color: #acacac;
                            margin-right: 10px;
                        }
                    }
                }
            }

            .flushing-block {
                background: #FFFFFF;
                border-radius: 10px;
                position: relative;
                min-height: 120px;
                
                .flushing-content-wrapper {
                    padding: 10px;

                    .form-title {
                        display: flex;
                        flex-direction: row;
                        align-items: center;

                        .form-title-wrapper {
                            padding-bottom: 5px;
                            border-bottom: 1px solid rgba(2, 34, 89, 0.3);
                            display: flex;
                            flex-direction: row;     
                            align-items: center;

                            .form-status {
                                margin-right: 5px;
                                width: 20px;
                                height: 20px;
                            }

                            .process {
                                animation: 1s linear 0s normal none infinite running process;
                                -webkit-animation: 1s linear 0s normal none infinite running process;
                            }

                            @keyframes process {
                                0% {
                                    transform: rotate(0deg);
                                }
                                100% {
                                    transform: rotate(360deg);
                                }
                            }

                            @-webkit-keyframes process {
                                0% {
                                    transform: rotate(0deg);
                                }
                                100% {
                                    transform: rotate(360deg);
                                }
                            }

                            .block-name {
                                font-size: 14px;
                                color: #022259;
                            }
                        }
                    }

                    .flushing-text {
                        width: 70%;
                        margin-top: 10px;
                        font-size: 14px;
                    }

                    .test-table-form-content {
                        margin-top: 10px;

                        .content-item {
                            display: flex;
                            flex-direction: row;
                            margin-top: 10px;
                            align-items: center;

                            .form-status {
                                width: 20px;
                                height: 20px;
                                margin-right: 5px;
                            }

                            .process {
                                animation: 1s linear 0s normal none infinite running process;
                                -webkit-animation: 1s linear 0s normal none infinite running process;
                            }

                            @keyframes process {
                                0% {
                                    transform: rotate(0deg);
                                }
                                100% {
                                    transform: rotate(360deg);
                                }
                            }

                            @-webkit-keyframes process {
                                0% {
                                    transform: rotate(0deg);
                                }
                                100% {
                                    transform: rotate(360deg);
                                }
                            }

                            .content-text {
                                font-size: 14px;
                            }
                        }
                    }

                    .timer-wrapper {
                        position: absolute;
                        right: 30px;
                        top: 11px;
                        width: auto;
                        height: auto;
                        display: flex;
                        flex-direction: column;
                        align-items: center;

                        .rinsing-image {
                            width: 120px;
                            height: 70px;
                        }

                        .time-current-test {
                            span {
                                color: #022259 !important;
                            }
                        }

                        .time-error {
                            color: red !important;
                        }

                        .time {
                            margin-top: 10px;
                            font-size: 18px;
                            color: #022259;

                            span {
                                color: green;
                            }
                        }

                        .timer-title {
                            color: #022259;
                            font-weight: 500;
                        }                        
                    }

                    .flushing-block-button {
                        margin-top: 10px;
                        width: 150px;
                        height: 35px;
                        background: #FFFFFF;
                        border: 2px solid #022259;
                        border-radius: 18px;
                        color: #022259;
                        background: #022259;
                        color: #FFFFFF;
                    }

                    .flushing-block-button:hover {
                        background: #FFFFFF;
                        color: #022259;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1260px) {

    .warning-block-wrapper {
        padding: 15px 25px 0 25px !important;
    }

    .flushing-content-wrapper {
        padding: 15px 25px 15px 25px !important;
    }

    .flushing-text, .flushing-block-button {
        margin-top: 15px !important;
    }

    .timer-wrapper {
        right: 105px !important;
        top: 15px !important;
    }

    .monitoring-table-form {
        grid-template-columns: 2.4fr 6fr 2fr 1fr !important;
        .form-title {
            max-width: 148px !important;
            min-width: 148px !important;
            .form-status {
                margin-left: 15px !important;
            }
        }
    }

    .form-control {
        min-width: 100px !important;

        button {
            margin-right: 15px !important;
        }
    }

    .router-table-head {
        padding: 25px 25px 13px 25px !important;
    }

    .router-table-body {
        padding: 0 25px 0 25px !important;
    }

    .second-row-body {
        padding: 25px 25px 25px 25px !important;
    }

    .buttons-wrapper {
        padding-left: 25px !important;
        padding-bottom: 25px !important;
    }

    .buttons-wrapper {

        button {
            width: 210px !important;
        }
    }
}

@media screen and (max-width: 1400px) {
    .form-title {
        min-width: 130px !important;
        margin-right: 10px !important;
    }

    .form-date {
        min-width: 120px !important;
    }
}

@media screen and (min-width: 1920px) {
    .monitoring-table {
        height: calc(100% - 58px) !important;
        padding: 29px !important;
    }

    .monitoring-table-body-wrapper {
        height: calc(100% - 48px) !important;
        margin-top: 20px !important;
        font-size: 18px !important;
    }

    .monitoring-table-form {
        height: 50px !important;
        grid-template-columns: 2.4fr 4fr 2fr 1fr !important;

        .form-title {
            max-width: 178px !important;
            min-width: 178px !important;
            .form-status {
                margin-left: 15px !important;
                width: 20px !important;
                height: 20px !important;
            }
        }

        span {
            font-size: 18px !important;
        }
    }

    .monitoring-table-title {
        padding-bottom: 10px !important;

        .table-name {
            font-size: 21px !important;

            .subtitle {
                font-size: 18px !important;
            }
        }
    }

    .monitoring-table-form-wrapper {
        margin-top: 13px !important;
    }

    .monitoring-table-form-wrapper:nth-child(1) {
        margin-top: 0 !important;
    }

    .form-control {
        min-width: 125px !important;
        max-width: 135px !important;

        .form-indicators {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 65px !important;
            align-items: center;

            .icon-wrapper {
                width: 30px !important;
                height: 30px !important;
            }
        }

        button {
            width: 30px !important;
            height: 30px !important;
            margin-right: 15px !important;
        }
    }

    .selected-device {
        border: 2px solid #022259 !important;
    }

    .hight-temperature {
        border: 2px solid red !important;
    }

    .hidden-block {
        font-size: 18px !important;

        .router-table-head {
            padding: 25px 25px 13px 25px !important;

            .router-table-row {
                .router-table-column {
                    .router-column {
                        .router-column-title {
                            font-size: 18px !important;
                        }

                        .router-column-device-title {
                            margin-right: 22px !important;
                        }
                    }
                }
            }
        }

        .router-table-body {
            padding: 0 25px 0 25px !important;

            .router-table-column {
                height: 35px !important;
                
                span {
                    font-size: 18px !important;
                }
            }
        }

        .second-row-body {
            padding: 25px 25px 25px 25px !important;
            height: 76px !important;
            grid-column-gap: 15px !important;

            .router-table-title {
                font-size: 18px !important;
            }

            .router-table-block {
                height: 35px !important;

                span {
                    font-size: 18px !important;
                }
            }
        }

        .buttons-wrapper {
            padding-left: 25px !important;
            padding-bottom: 25px !important;

            button {
                font-size: 18px !important;
                height: 45px !important;
                border-radius: 22px !important; 
            }

            .unlink {
                width: 32.3% !important;
            }

            .testing, .disabled-button {
                width: 300px !important;
            }

            .status-button {
                width: 150px !important;
            }
        }
    }

    .warning-block-wrapper {
        padding: 10px 25px 0 25px !important;
    }

    .warning-block {
        font-size: 18px !important;
        padding: 20px !important;   
        
        .warning-block-title {
            img {
                width: 30px !important;
                height: 30px !important;
                margin-right: 10px !important;
            }

            span {
                font-size: 18px !important;
            }
        }
    }

    .flushing-block {
        height: auto !important;

        .flushing-content-wrapper {
            padding: 25px !important;

            .form-title {
                .form-title-wrapper {
                    padding-bottom: 10px !important;
                    .form-status {
                        margin-right: 10px;
                        width: 30px !important;
                        height: 30px !important;
                    }

                    .block-name {
                        font-size: 18px !important;
                    }
                }
            }

            .flushing-text {
                margin-top: 20px !important;
                font-size: 18px !important;
            }

            .test-table-form-content {
                margin-top: 10px;

                .content-item {
                    .form-status {
                        margin-right: 10px;
                        width: 30px !important;
                        height: 30px !important;
                    }

                    .content-text {
                        font-size: 18px !important;
                    }
                }
            }

            .timer-wrapper {
                right: 90px !important;
                top: 48px !important;

                .time {
                    font-size: 21px !important;
                }

                .timer-title {
                    font-size: 21px !important;
                }                        
            }

            .flushing-block-button {
                margin-top: 20px !important;
                height: 45px !important;
                border-radius: 22px !important;
                font-size: 18px !important;
            }
        }
    }
}

@media screen and (min-width: 2560px) {
    .monitoring-table {
        height: calc(100% - 78px) !important;
        padding: 39px !important;
    }

    .monitoring-table-title {
        .table-name {
            font-size: 36px !important;

            .subtitle {
                font-size: 24px !important;
            }
        }
    }

    .monitoring-table-body-wrapper {
        height: calc(100% - 72px) !important;
        margin-top: 30px !important;
        font-size: 24px !important;
    }

    .monitoring-table-form {
        height: 70px !important;
        grid-template-columns: 2.5fr 4fr 2fr 1fr !important;
        border-radius: 10px !important;

        .form-title {
            max-width: 245px !important;
            min-width: 240px !important;
            .form-status {
                margin-left: 20px !important;
                width: 30px !important;
                height: 30px !important;
                margin-right: 10px !important;
            }

            .strang-name {
                font-size: 24px !important;
            }
        }

        span {
            font-size: 24px !important;
        }
    }

    .monitoring-table-form-wrapper:nth-child(1) {
        margin-top: 0 !important;
    }

    .form-control {
        min-width: 165px !important;

        .form-indicators {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 87px !important;
            align-items: center;

            .icon-wrapper {
                width: 40px !important;
                height: 40px !important;
            }
        }

        button {
            width: 40px !important;
            height: 40px !important;
            margin-right: 20px !important;
        }
    }

    .hidden-block {
        font-size: 24px !important;

        .router-table-head {
            padding: 30px 30px 23px 30px !important;

            .router-table-row {
                .router-table-column {
                    .router-column {
                        .router-column-title {
                            font-size: 24px !important;
                        }

                        .router-column-device-title {
                            margin-right: 24px !important;
                        }
                    }
                }
            }
        }

        .router-table-body {
            padding: 0 30px 0 30px !important;

            .router-table-column {
                height: 50px !important;
                
                span {
                    font-size: 24px !important;
                }
            }
        }

        .second-row-body {
            padding: 30px 30px 30px 30px !important;
            height: 107px !important;
            grid-column-gap: 50px !important;

            .router-table-title {
                font-size: 24px !important;
            }

            .router-table-block {
                height: 50px !important;

                span {
                    font-size: 24px !important;
                }
            }
        }

        .buttons-wrapper {
            padding-left: 30px !important;
            padding-bottom: 30px !important;

            button {
                font-size: 24px !important;
                height: 60px !important;
                border-radius: 30px !important; 
            }

            .unlink {
                width: 300px !important;
                margin-right: 45px !important;
            }

            .testing, .disabled-button {
                width: 350px !important;
                margin-right: 45px !important;
            }

            .status-button {
                width: 175px !important;
            }
        }
    }

    .warning-block-wrapper {
        padding: 20px 30px 0 30px !important;
    }

    .warning-block {
        font-size: 24px !important;
        padding: 18px !important;   
        
        .warning-block-title {
            margin-top: 15px !important;

            img {
                width: 40px !important;
                height: 40px !important;
                margin-right: 20px !important;
            }

            span {
                font-size: 24px !important;
            }
        }
    }

    .flushing-block {
        height: auto !important;

        .flushing-content-wrapper {
            padding: 30px !important;

            .form-title {
                .form-title-wrapper {
                    padding-bottom: 10px !important;

                    .form-status {
                        margin-right: 10px;
                        width: 40px !important;
                        height: 40px !important;
                    }

                    .block-name {
                        font-size: 24px !important;
                    }
                }
            }

            .flushing-text {
                margin-top: 20px;
                font-size: 24px !important;
            }

            .test-table-form-content {
                .content-item {
                    .form-status {
                        margin-right: 10px;
                        width: 40px !important;
                        height: 40px !important;
                    }

                    .content-text {
                        font-size: 24px !important;
                    }
                }
            }

            .timer-wrapper {
                right: 30px !important;
                top: 42px !important;

                .rinsing-image {
                    width: 200px !important;
                    height: 110px !important;
                }

                .time {
                    font-size: 36px !important;
                }

                .timer-title {
                    font-size: 36px !important;
                }                        
            }

            .flushing-block-button {
                margin-top: 20px !important;
                height: 60px !important;
                border-radius: 30px !important;
                font-size: 24px !important;
            }
        }
    }

}

@media screen and (min-width: 3840px) {
    .monitoring-table {
        height: calc(100% - 130px) !important;
        padding: 65px !important;
    }

    .monitoring-table-title {
        .table-name {
            font-size: 48px !important;

            .subtitle {
                font-size: 36px !important;
            }
        }
    }

    .monitoring-table-body-wrapper {
        height: calc(100% - 94px) !important;
        margin-top: 40px !important;
        font-size: 36px !important;
    }

    .monitoring-table-form {
        height: 90px !important;
        grid-template-columns: 2.3fr 4fr 2fr 1fr !important;
        border-radius: 10px !important;

        .form-title {
            max-width: 360px !important;
            min-width: 350px !important;
            .form-status {
                margin-left: 35px !important;
                width: 40px !important;
                height: 40px !important;
            }

            .strang-name {
                font-size: 36px !important;
            }
        }

        span {
            font-size: 36px !important;
        }
    }

    .monitoring-table-form-wrapper:nth-child(1) {
        margin-top: 0 !important;
    }

    .form-control {
        min-width: 210px !important;

        .form-indicators {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 110px !important;
            align-items: center;

            .icon-wrapper {
                width: 50px !important;
                height: 50px !important;
            }
        }

        button {
            width: 50px !important;
            height: 50px !important;
            margin-right: 35px !important;
        }
    }

    .hidden-block {
        font-size: 36px !important;

        .router-table-head {
            padding: 45px 45px 23px 45px !important;

            .router-table-row {
                .router-table-column {
                    .router-column {
                        .router-column-title {
                            font-size: 36px !important;
                        }
                    }

                    .router-column-device-title {
                        margin-right: 30px !important;
                    }
                }
            }
        }

        .router-table-body {
            padding: 0 45px 0 45px !important;

            .router-table-column {
                height: 60px !important;
                
                span {
                    font-size: 36px !important;
                }
            }
        }

        .second-row-body {
            padding: 45px !important;
            height: 139px !important;
            grid-column-gap: 50px !important;

            .router-table-title {
                font-size: 36px !important;
            }

            .router-table-block {
                height: 60px !important;

                span {
                    font-size: 36px !important;
                }
            }
        }

        .buttons-wrapper {
            padding-left: 45px !important;
            padding-bottom: 45px !important;

            button {
                font-size: 36px !important;
                height: 70px !important;
                border-radius: 40px !important; 
            }

            .unlink {
                width: 450px !important;
            }

            .testing, .disabled-button {
                width: 500px !important;
            }

            .status-button {
                width: 250px !important;
            }
        }
    }

    .warning-block-wrapper {
        padding: 35px 45px 0 45px !important;
    }

    .warning-block {
        font-size: 36px !important;
        padding: 28px !important;   
        
        .warning-block-title {
            margin-top: 20px !important;

            img {
                width: 50px !important;
                height: 50px !important;
                margin-right: 20px !important;
            }

            span {
                font-size: 36px !important;
            }
        }
    }

    .flushing-block {
        height: auto !important;

        .flushing-content-wrapper {
            padding: 45px !important;
            width: calc(100% - 100px) !important;

            .form-title {
                .form-title-wrapper {
                    padding-bottom: 10px !important;

                    .form-status {
                        margin-right: 20px;
                        width: 50px !important;
                        height: 50px !important;
                    }

                    .block-name {
                        font-size: 36px !important;
                    }
                }
            }

            .flushing-text {
                margin-top: 30px !important;
                font-size: 36px !important;
            }

            .test-table-form-content {
                .content-item {
                    .form-status {
                        margin-right: 20px;
                        width: 50px !important;
                        height: 50px !important;
                    }

                    .content-text {
                        font-size: 36px !important;
                    }
                }
            }

            .timer-wrapper {
                right: 80px !important;
                top: 80px !important;


                .time {
                    font-size: 48px !important;
                }

                .timer-title {
                    font-size: 48px !important;
                }                        
            }

            .flushing-block-button {
                height: 70px !important;
                border-radius: 40px !important;
                font-size: 36px !important;
                margin-top: 30px !important;
                width: 250px !important;
            }
        }
    }

}
</style>
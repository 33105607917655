<template>
    <div class="logs-block-wrapper">
        <div class="logs-block">
            <div class="logs-block-header-wrapper">
                <div class="logs-block-title">
                    <p class="block-name"><b>Spül Log</b></p>
                    <img v-if="!showFilters" src="/images/filter_icon.png" alt="Show filters" class="filter-icon" @click="showFilters = !showFilters">
                    <img v-else src="/images/filter_icon_active.png" alt="Show filters" class="filter-icon" @click="showFilters = !showFilters">
                </div>
                <div v-if="showFilters">
                    <div class="filters-block">
                        <img src="/images/sort_icon.png" alt="Sort icon" class="filter-sort-icon" @click="sortLogs()">
                        <p class="fiter-date-title">Datum:</p>
                        <div class="filter-date-container">
                            <DatePicker v-model="firstDate" locale="de" :masks="masks" class="date-picker">
                                <template v-slot="{ inputValue, inputEvents }">
                                    <input 
                                        type="text"
                                        placeholder="Startdatum"
                                        class="filter-input"
                                        :value="inputValue"
                                        v-on="inputEvents"
                                    >
                                </template>
                            </DatePicker>
                            <p class="delimiter"></p>
                            <DatePicker v-model="secondDate" locale="de" :masks="masks" class="date-picker">
                                <template v-slot="{ inputValue, inputEvents }">
                                    <input 
                                        type="text"
                                        placeholder="Endtermin"
                                        class="filter-input"
                                        :value="inputValue"
                                        v-on="inputEvents"
                                    >
                                </template>
                            </DatePicker>
                            <img v-if="firstDate != '' || secondDate != ''" src="/images/cancel2.png" alt="Exit icon" class="filter-close-button" @click="cleanFiltersDate">
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="getLogs.length !== 0" class="logs-block-content-wrapper">
                <div class="logs-block-content">
                    <div class="logs-item-wrapper">
                        <div class="logs-item" v-for="log in filteredLogs" :key="log.id" :class="{'warning-logs-item': log.log_type === 2}">
                            <div class="row-wrapper data-wrapper">
                                <div class="logs-item-date-wrapper">
                                    <div class="logs-item-date">
                                        <img v-if="log.log_type === 2" src="/images/error.png" alt="Error icon">
                                        <p>{{ formatDate(log.created_at) }} Uhr</p>
                                    </div>
                                </div>
                                <div class="logs-item-data">
                                    <div class="logs-item-data-row">
                                        <div class="row-dot-wrapper">
                                            <div class="row-dot"></div>
                                        </div>
                                        <p>{{ log.message }}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="comment-button">
                                <img v-if="!log.showComment && log.comment != '' && log.comment" src="/images/edit_icon.png" alt="Edit icon" @click="log.showComment = !log.showComment">
                                <img v-else src="/images/comment_icon.png" alt="Comment icon" @click="log.showComment = !log.showComment">
                            </div>
                            <div v-if="log.showComment" class="comment-row-wrapper">
                                <p class="comment-title">Kommentar:</p>
                                <input type="text" v-model="log.comment" class="input-comment">
                                <button class="first-button" @click="deleteComment(log)">Abbrechen</button>
                                <button class="second-button" @click="updateComment(log)">Speichern</button>
                            </div>
                            <div v-else-if="log.comment != '' && !log.showComment && log.comment" class=" row-wrapper comment-row">
                                <p class="comment-title">Kommentar:</p>
                                <p class="comment-content">{{ log.comment }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment';
import validationTimeZone from "../mixins/validationTimeZone.mixin";
import { mapGetters } from 'vuex';
import { DatePicker } from 'v-calendar'
export default {
  name: 'Logs Block',
  mixins: [validationTimeZone],
  components: {
    DatePicker
  },
  data() {
    return {
        showFilters: false,
        firstDate: '',
        secondDate: '',
        masks: {
            input: 'DD.MM.YYYY',
        },
        sort: 'dsc',
    }
  },
  computed: {
    ...mapGetters(['getLogs']),
    filteredLogs: function() {
        const startDate = moment(this.firstDate).format('YYYY-MM-DD');
        const endDate = moment(this.secondDate).format('YYYY-MM-DD');
        return this.getLogs.filter(log => {
            if(this.firstDate === '' || this.secondDate === '') {
                return true;
            } else {
                let currentLog = moment(log.created_at).format('YYYY-MM-DD')
                return moment(currentLog).isSameOrAfter(startDate) && moment(currentLog).isSameOrBefore(endDate);
            }
        })
    }
  },
  methods: {
    formatDate(date) {
        const timeZone = this.validationTimeZone(date);
        return moment(timeZone).format('DD.MM.YYYY HH:mm');
    },
    deleteComment(log) {
        this.$socket.emit('logs/comment', {
            token: localStorage.getItem('token'),
            comment: '',
            log: log._id
        });
        log.showComment = !log.showComment;
    },
    updateComment(log) {
        this.$socket.emit('logs/comment', {
            token: localStorage.getItem('token'),
            comment: log.comment,
            log: log._id
        });
        log.showComment = !log.showComment;
    },
    cleanFiltersDate() {
        this.firstDate = '';
        this.secondDate = '';
    },
    sortLogs() {
        if(this.sort === 'asc') {
            this.getLogs.sort((a,b) => {
                return new Date(b.created_at) - new Date(a.created_at);
            })
            this.sort = 'dsc'
        } else {
            this.getLogs.sort((a,b) => {
                return new Date(a.created_at) - new Date(b.created_at);
            })
            this.sort = 'asc'
        }
    }
  }
}
</script>
<style lang="scss" scoped>
.logs-block-wrapper {
    width: 100%;
    height: 100%;
    background: #EFF9FF;
    border-radius: 10px;

    .logs-block {
        height: calc(100% - 40px);
        padding: 20px;
        position: relative;

        .logs-block-header-wrapper {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            .logs-block-title {
                display: flex;
                flex-direction: row;
                align-items: center;
                padding-bottom: 5px;
                border-bottom: 1px solid rgba(2, 34, 89, 0.3);

                .block-name {
                    font-size: 18px;
                    color: #022259;

                    .subtitle {
                        font-size: 14px;
                    }
                }

                .filter-icon {
                    margin-left: 10px;
                    width: 15px;
                    height: 15px;
                }
            }

            .filters-block {
                position: absolute;
                display: flex;
                flex-direction: row;
                align-items: center;
                top: 14px;
                right: 20px;

                .filter-sort-icon {
                    margin-right: 3px;
                    width: 17px;
                    height: 17px;
                }

                .fiter-date-title {
                    margin-right: 10px;
                    color: #022259;
                    font-size: 14px;
                    font-weight: 500;
                }

                .filter-date-container {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    border: 2px solid #022259;
                    height: 31px;
                    border-radius: 10px;
                    background: #FFFFFF;
                    padding-left: 2px;
                    padding-right: 2px;
                    margin-right: 30px;
                    position: relative;

                    input::-webkit-input-placeholder { color: #022259; }

                    .filter-input {
                        border: none;
                        width: 110px;
                        color: #022259;
                        background-image: url('/images/calendar.png');
                        background-size: contain;
                        background-repeat: no-repeat;   
                        background-position: 102px;  
                        outline: none;
                        padding-left: 10px;
                        padding-right: 10px;
                        font-size: 14px;
                    }

                    .delimiter {
                        content: '';
                        width: 15px;
                        height: 1px;
                        margin: 0 auto;
                        background-color: #022259;
                    }

                    .filter-close-button {
                        position: absolute;
                        top: 7px;
                        right: -30px;
                        width: 17px;
                        height: 17px;
                    }
                }
            }
        }

        .logs-block-content-wrapper {
            margin-top: 21px;
            width: 100%;
            height: calc(100% - 51px);

            .logs-block-content {
                overflow-x: auto;
                height: 100%;
                padding-top: 5px;

                .logs-item-wrapper {
                    width: calc(100% - 15px);

                    .logs-item {
                        position: relative;
                        margin-top: 10px;
                        background: #FFFFFF;
                        border-radius: 10px;
                        padding: 10px;

                        p {
                            font-size: 14px;
                        }

                        .row-wrapper {
                            display: grid;
                            grid-template-columns: 35% 64%;
                            grid-column-gap: 5px;
                            align-self: center;
                        }

                        .logs-item-date-wrapper {
                            align-self: center;

                            .logs-item-date {
                                display: flex;
                                flex-direction: row;
                                align-items: center;

                                img {
                                    width: 20px;
                                    height: 20px;
                                    margin-right: 5px;
                                }
                            }
                        }

                        .logs-item-data {
                            align-self: center;

                            .logs-item-data-row {
                                display: flex;
                                flex-direction: row;
                                align-items: center;

                                .row-dot-wrapper {
                                    min-width: 9px;

                                    .row-dot {
                                        width: 4px;
                                        height: 4px;
                                        border-radius: 50%;
                                        background: black;
                                    }
                                }

                                p {
                                    margin-left: 5px;
                                }
                            }

                            .warning {
                                margin-top: 5px;
                            }
                        }

                        .comment-row-wrapper {
                            margin-top: 10px;
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: space-between;

                            button {
                                width: 130px;
                                height: 35px;
                                border-radius: 18px;
                                text-align: center;
                            }

                            .first-button {
                                border: 2px solid #022259;
                                color: #FFFFFF;
                                background: #022259;
                            }

                            .second-button {
                                border: 2px solid #022259;
                                color: #FFFFFF;
                                background: #022259;
                            }

                            .input-comment {
                                width: 150px;
                                background: #FFFFFF;
                                border: 2px solid #022259;
                                border-radius: 7px;
                                height: 10px;
                                font-size: 14px;
                                color: #022259;
                                padding: 10px;
                            }
                        }

                        .comment-row {
                            margin-top: 10px;
                            align-items: center;

                            .comment-title {
                                display: flex;
                                justify-content: end;
                                margin-right: 30px;
                            }

                            .comment-content {
                                word-wrap: break-word;
                                color: #022259;
                            }

                            .input-comment {
                                background: #FFFFFF;
                                border: 2px solid #022259;
                                border-radius: 7px;
                                height: 10px;
                                font-size: 14px;
                                color: #022259;
                                padding: 10px;
                            }
                        }

                        .comment-button {
                            position: absolute;
                            width: 30px;
                            height: 30px;
                            border: 1px solid #022259;
                            background: #FFFFFF;
                            border-radius: 50%;
                            right: -15px;
                            top: -15px;

                            img {
                                width: 17px;
                                height: 17px;
                                margin-top: 6px;
                                margin-left: 6px;
                                cursor: pointer;
                            }
                        }
                    }

                    .warning-logs-item {
                        background: rgb(255, 202, 202);
                        border: 1px solid red;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1260px) {
    .logs-block-content {
        height: 210px !important;
    }

    .logs-item {
        padding: 15px 25px 15px 25px !important;
    }

    .comment-buttons {
        margin-right: 5px !important;
    }

    .input-comment, button {
        width: 270px !important;
    }
}

@media screen and (min-width: 1920px) {
    .block-name b {
        font-size: 21px !important;
    }

    .subtitle, b, p {
        font-size: 18px !important;
    }

    .logs-block {
        padding: 29px !important;
        height: calc(100% - 58px) !important;
    }

    .logs-block-title {
        padding-bottom: 10px !important;

        .filter-icon {
            margin-left: 10px;
            width: 19px !important;
            height: 19px !important;
        }
    }

    .filters-block {
        top: 15px !important;
        right: 29px !important;

        .filter-sort-icon {
            margin-right: 5px !important;
            width: 20px !important;
            height: 20px !important;
        }

        .fiter-date-title {
            margin-right: 15px !important;
            font-size: 18px !important;
        }

        .filter-date-container {
            height: 41px !important;
            padding-left: 2px;
            padding-right: 2px;
            margin-right: 35px !important;

            .date-picker {
                font-size: 18px !important;
            }


            input::-webkit-input-placeholder { font-size: 18px !important; }


            .filter-input {
                width: 140px !important; 
                background-position: 130px !important;  
                padding-left: 15px !important;
                padding-right: 15px !important;
                font-size: 18px !important;
                height: 25px;
            }
        }

        .filter-close-button {
            top: 10px !important;
            right: -47px !important;
            width: 20px !important;
            height: 20px !important;
        }
    }

    .logs-block-content {
        height: calc(100% - 15px) !important;
        padding-top: 10px !important;
    }

    .logs-item-wrapper {
        width: calc(100% - 20px) !important;
    }

    .logs-item {
        padding: 15px 25px 15px 25px !important;
    }

    img {
        width: 30px !important;
        height: 30px !important;
        margin-right: 10px !important;
    }

    .row-dot {
        margin-right: 10px !important;
    }

    .input-comment {
        width: 220px !important;
        height: 19px !important;
        font-size: 18px !important;
    }

    .comment-button {
        width: 40px !important;
        height: 40px !important;
        right: -20px !important;
        top: -20px !important;

        img {
            width: 20px !important;
            height: 20px !important;
            margin-top: 10px !important;
            margin-left: 10px !important;
        }
    }
    
    .comment-row-wrapper, .comment-row {
        margin-top: 15px !important;
    }

    button {
        height: 45px !important;
        width: 160px !important;
        border-radius: 22px !important;
        font-size: 18px !important;
    }
    
}

@media screen and (min-width: 2560px) {

    .block-name b {
        font-size: 36px !important;
    }

    .subtitle, b, p {
        font-size: 24px !important;
    }

    .logs-block-content-wrapper {
        margin-top: 30px !important;
        height: calc(100% - 70px) !important;
    }

    .logs-block {
        padding: 39px !important;
        height: calc(100% - 78px) !important;

        .logs-block-title {
            .filter-icon {
                width: 30px !important;
                height: 30px !important;
            }
        }
    }

    .filters-block {
        top: 25px !important;
        right: 39px !important;

        .filter-sort-icon {
            margin-right: 5px !important;
            width: 27px !important;
            height: 27px !important;
        }

        .fiter-date-title {
            margin-right: 20px !important;
            font-size: 24px !important;
        }

        .filter-date-container {
            height: 56px !important;
            padding-left: 2px;
            padding-right: 2px;
            margin-right: 45px !important;

            .date-picker {
                font-size: 24px !important;
            }


            input::-webkit-input-placeholder { font-size: 24px !important; }


            .filter-input {
                width: 170px !important; 
                background-position: 154px !important;  
                padding-left: 15px !important;
                padding-right: 15px !important;
                font-size: 24px !important;
                height: 30px;
            }
        }

        .filter-close-button {
            top: 14px !important;
            right: -57px !important;
            width: 25px !important;
            height: 25px !important;
        }
    }

    .logs-block-content {
        height: calc(100% - 20px) !important;
        padding-top: 15px !important;
    }

    .logs-item-wrapper {
        width: calc(100% - 25px) !important;
    }

    .logs-item {
        padding: 30px !important;
    }

    img {
        width: 40px !important;
        height: 40px !important;
        margin-right: 10px !important;
    }

    .comment-button {
        width: 50px !important;
        height: 50px !important;
        right: -25px !important;
        top: -25px !important;

        img {
            margin-top: 12px !important;
            margin-left: 12px !important;
            width: 26px !important;
            height: 26px !important;
        }
    }

    .comment-row-wrapper, .comment-row {
        margin-top: 30px !important;
    }

    button {
        height: 60px !important;
        border-radius: 30px !important;
        font-size: 24px !important;
        width: 220px !important;
    }

    .input-comment {
        height: 36px !important;
        font-size: 24px !important;
        width: 280px !important;
    }
}

@media screen and (min-width: 3840px) {

    .block-name b {
        font-size: 48px !important;
    }

    .subtitle, b, p {
        font-size: 36px !important;
    }

    .logs-block-content-wrapper {
        margin-top: 40px !important;
    }

    .logs-block {
        padding: 65px !important;
        width: calc(100% - 130px) !important;

        .logs-block-title {
            .filter-icon {
                margin-left: 15px !important;
                width: 40px !important;
                height: 40px !important;
            }
        }
    }

    .filters-block {
        top: 53px !important;
        right: 65px !important;

        .filter-sort-icon {
            margin-right: 10px !important;
            width: 35px !important;
            height: 35px !important;
        }

        .fiter-date-title {
            margin-right: 25px !important;
            font-size: 36px !important;
        }

        .filter-date-container {
            height: 66px !important;
            padding-left: 2px;
            padding-right: 2px;
            margin-right: 55px !important;

            .date-picker {
                font-size: 36px !important;
            }

            input::-webkit-input-placeholder { font-size: 36px !important; }

            .filter-input {
                width: 260px !important; 
                background-position: 232px !important;  
                padding-left: 15px !important;
                padding-right: 15px !important;
                font-size: 36px !important;
                height: 40px !important;
            }

            .delimiter {
                height: 2px !important;
            }
        }

        .filter-close-button {
            top: 16px !important;
            right: -77px !important;
            width: 30px !important;
            height: 30px !important;
        }
    }

    .logs-block-content {
        height: calc(100% - 80px) !important;
        padding-top: 0 !important;
    }

    .logs-item-wrapper {
        width: calc(100% - 30px) !important;
    }

    .logs-item {
        padding: 30px !important;
    }

    img {
        width: 50px !important;
        height: 50px !important;
        margin-right: 10px !important;
    }

    .input-comment {
        font-size: 36px !important;
    }

    .comment-buttons {
        margin-right: 5px !important;
    }

    .comment-button {
        width: 60px !important;
        height: 60px !important;
        right: -30px !important;
        top: -30px !important;

        img {
            width: 36px !important;
            height: 36px !important;
        }
    }

    button {
        width: 400px !important;
        height: 70px !important;
        border-radius: 40px !important;
        font-size: 36px !important;
    }

    .input-comment {
        height: 46px !important;
        font-size: 36px !important;
        width: 400px !important;
    }

    .logs-item-wrapper {
        .logs-item {
            margin-top: 30px !important;
        }
    }
}
</style>
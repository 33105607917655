<template>
  <div class="monitoring-container">
    <div class="monitoring-blocks">
      <div class="left-monitoring-block">
        <monitoring-linked-flats class="result-monitoring-table"></monitoring-linked-flats>
      </div>
      <div class="right-monitoring-block">
          <logs-block class="monitoring-logs-block"></logs-block>
      </div>
    </div>
  </div>
</template>

<script>
import MonitoringLinkedFlats from '../../components/MonitoringLinkedFlats.vue';
import LogsBlock from '../../components/LogsBlock.vue';

export default {
  name: 'Monitoring',
  components: {
    MonitoringLinkedFlats,
    LogsBlock
  }
}
</script>

<style lang="scss">
.monitoring-container {
  position: absolute;
  margin: 120px 25px 40px 25px;
  width: calc(100% - 50px);
  height: calc(100% - 160px);

  .monitoring-blocks {
    height: 100%;
    display: grid;
    grid-template-columns: 1fr 1.2fr;
    grid-column-gap: 13px;

    .left-monitoring-block {
      height: calc(100vh - 160px);
      width: 100%;
    }

    .right-monitoring-block {
        .monitoring-logs-block {
          width: 100%;
          height: calc(100vh - 160px);
        }
    }
  }
}

@media screen and (max-width: 1260px) {
  .monitoring-blocks {
    display: flex !important; 
    flex-direction: column !important;
    height: auto !important;

    .left-monitoring-block {
      height: 400px !important;
    }

    .right-monitoring-block {
      margin-top: 13px;
      height: 400px !important;
      margin-bottom: 40px !important;

      .monitoring-logs-block {
        height: 400px !important;
      }
    }
  }
}

::-webkit-scrollbar {
  background-color: #E5E5E5;
  width: 7px;
}

::-webkit-scrollbar-thumb {
  margin-right: 5px;
  background-color: #022259;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #003797;
}

@media screen and (min-width: 1920px) {
  .monitoring-container {
    margin: 125px 30px 45px 30px !important;
    width: calc(100% - 60px) !important;
    height: calc(100% - 170px) !important;
  }
}

@media screen and (min-width: 2560px) {
  .monitoring-container {
    margin: 165px 40px 60px 40px !important;
    width: calc(100% - 80px) !important;
    height: calc(100% - 225px) !important;
  }

  .monitoring-blocks {
    grid-column-gap: 29px !important;
  }


  .left-monitoring-block {
    height: calc(100vh - 225px) !important;
  }

  .monitoring-logs-block {
    height: calc(100vh - 225px) !important;
  }
}

@media screen and (min-width: 3840px) {
  .monitoring-container {
    margin: 235px 50px 60px 50px !important;
    width: calc(100% - 100px) !important;
    height: calc(100% - 295px) !important;
  }

  .monitoring-blocks {
    grid-column-gap: 29px !important;
  }

  .left-monitoring-block {
    height: calc(100vh - 295px) !important;
  }

  .monitoring-logs-block {
    height: calc(100vh - 295px) !important;
  }
}
</style>
